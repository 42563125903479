import { Component, TemplateRef } from '@angular/core';
import { faPlus, faEllipsisH, faSave } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CreateFoodCommand, FoodClient, FoodDto, FoodItemClient, FoodItemDto, FoodsVm, UpdateFoodItemCommand,
  CreateFoodItemCommand,
  IngredientDto,
  UpdateFoodCommand, RecipeClient, CreateRecipeCommand
} from '../web-api-client';


@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.scss']
})
export class FoodComponent {

  debug = false;

  vm: FoodsVm;

  selectedFood: FoodDto;
  selectedItem: FoodItemDto;

  newFoodEditor: any = {};
  foodOptionsEditor: any = {};
  recipeEditor: any = {};

  newFoodModalRef: BsModalRef;
  foodOptionsModalRef: BsModalRef;
  deleteFoodModalRef: BsModalRef;
  itemDetailsModalRef: BsModalRef;
  recipeModalRef: BsModalRef;

  faPlus = faPlus;
  faEllipsisH = faEllipsisH;
  faSave = faSave;

  recipeId: number;
 
  constructor(private foodsClient: FoodClient, private itemsClient: FoodItemClient, private recipeClient: RecipeClient, private modalService: BsModalService) {
    foodsClient.get(null, null).subscribe(
      result => {
        this.vm = result;
        if (this.vm.foods.length) {
          this.selectedFood = this.vm.foods[0];
        }
      },
      error => console.error(error)
    );
  }

  remainingItems(food: FoodDto): number {
    return food.items.length;
  }

  showNewFoodModal(template: TemplateRef<any>): void {
    this.newFoodModalRef = this.modalService.show(template);
    setTimeout(() => document.getElementById("name").focus(), 250);
  }

  newFoodCancelled(): void {
    this.newFoodModalRef.hide();
    this.newFoodEditor = {};
  }

  refresh(){
    let date = this.vm.date;
    this.foodsClient.get(date, date).subscribe(
      result => {
        let selectedFoodId = this.selectedFood.id;
        this.vm = result;
        if (this.vm.foods.length) {
          this.selectedFood = this.vm.foods.find(f => f.id == selectedFoodId);
        }
      },
      error => console.error(error)
    );
  }

  addFood(): void {
    let food = FoodDto.fromJS({
      id: 0,
      name: this.newFoodEditor.name,
      items: [],
    });

    food.date = new Date();

    let createCommand = new CreateFoodCommand();
    createCommand.name = food.name;
    createCommand.date = food.date;
    createCommand.recipeId = this.recipeId;

    this.foodsClient.create(createCommand).subscribe(
      result => {
        food.id = result;
        this.vm.foods.push(food);
        this.selectedFood = food;
        this.newFoodModalRef.hide();
        this.newFoodEditor = {};
        this.recipeId = undefined;
        this.refresh();
      },
      error => {
        let errors = JSON.parse(error.response);

        if (errors && errors.Title) {
          this.newFoodEditor.error = errors.Title[0];
        }

        setTimeout(() => document.getElementById("name").focus(), 250);
      }
    );
  }

  showFoodOptionsModal(template: TemplateRef<any>) {
    this.foodOptionsEditor = {
      id: this.selectedFood.id,
      name: this.selectedFood.name,
    };

    this.foodOptionsModalRef = this.modalService.show(template);
  }

  showRecipeModal(template: TemplateRef<any>) {
    this.recipeEditor = {
      id: this.selectedFood.id,
      name: '',
    };

    this.recipeModalRef = this.modalService.show(template);
  }

  updateFoodOptions() {
    let command = new UpdateFoodCommand();
    command.name = this.foodOptionsEditor.name;
    command.id = this.selectedFood.id;

    this.foodsClient.update(this.selectedFood.id, command)
      .subscribe(
        () => {
          this.selectedFood.name = this.foodOptionsEditor.name,
          this.foodOptionsModalRef.hide();
          this.foodOptionsEditor = {};

          this.refresh();
        },
        error => console.error(error)
      );
  }

  saveRecipe() {
    let command = new CreateRecipeCommand();
    command.name = this.recipeEditor.name;
    command.sourceFoodId = this.selectedFood.id;

    this.recipeClient.create(command)
      .subscribe(
        () => {
          this.recipeModalRef.hide();
          this.recipeEditor = {};

          this.refresh();
        },
        error => console.error(error)
      );
  }

  confirmDeleteFood(template: TemplateRef<any>) {
    this.foodOptionsModalRef.hide();
    this.deleteFoodModalRef = this.modalService.show(template);
  }

  deleteFoodConfirmed(): void {
    this.foodsClient.delete(this.selectedFood.id).subscribe(
      () => {
        this.deleteFoodModalRef.hide();
        this.vm.foods = this.vm.foods.filter(t => t.id != this.selectedFood.id)
        this.selectedFood = this.vm.foods.length ? this.vm.foods[0] : null;

        this.refresh();
      },
      error => console.error(error)
    );
  }

  // Items

  showItemDetailsModal(template: TemplateRef<any>, item: FoodItemDto): void {
    if (item == null) {
      this.selectedItem = new FoodItemDto();
      this.selectedItem.foodId = this.selectedFood.id;
      this.selectedItem.id = 0;
    }
    else {
      this.selectedItem = item;
    }

    this.itemDetailsModalRef = this.modalService.show(template);
  }

  saveItem(): void {

    if (this.selectedItem.id == 0) {
      this.itemsClient.create(CreateFoodItemCommand.fromJS({ ...this.selectedItem, foodId: this.selectedFood.id }))
        .subscribe(
          result => {
            this.itemDetailsModalRef.hide();

            this.refresh();
          },
          error => console.error(error)
        );
    } else {
      this.itemsClient.update(this.selectedItem.id, UpdateFoodItemCommand.fromJS(this.selectedItem))
        .subscribe(
          () => {
            console.log('Update succeeded.');

            this.itemDetailsModalRef.hide();

            this.refresh();

          },
          error => console.error(error)
        );
    }
  }

  // Delete item
  deleteItem(item: FoodItemDto) {
    if (this.itemDetailsModalRef) {
      this.itemDetailsModalRef.hide();
    }

    if (item.id == 0) {
      let itemIndex = this.selectedFood.items.indexOf(this.selectedItem);
      this.selectedFood.items.splice(itemIndex, 1);
    } else {
      this.itemsClient.delete(item.id).subscribe(
        () => {
          this.selectedFood.items = this.selectedFood.items.filter(t => t.id != item.id);
          this.refresh();
        } ,
        error => console.error(error)
      );
    }
  }

  getIngredient(id: number) : IngredientDto {
    let ingredient = this.vm.ingredients.find(i => i.id == id);
    return ingredient;
  }

  valueChanged() {
    let ingredient = this.getIngredient(this.selectedItem.ingredientId);
    this.selectedItem.amount = this.selectedItem.value / ingredient.value * ingredient.amount;
  }

  amountChanged() {
    let ingredient = this.getIngredient(this.selectedItem.ingredientId);
    this.selectedItem.value = ingredient.value / ingredient.amount * this.selectedItem.amount;
  }

  dateChange(){
    this.foodsClient.get(this.vm.date, this.vm.date).subscribe(
      result => {
        this.selectedFood = null;
        this.vm = result;
        if (this.vm.foods.length) {
          this.selectedFood = this.vm.foods[0];
        }
      },
      error => console.error(error)
    );
  }
}
