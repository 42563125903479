<ul class="navbar-nav" *ngIf="isAuthenticated | async">
    <li class="nav-item">
        <a  class="nav-link text-dark" [routerLink]='["/authentication/profile"]' title="Manage">{{ userName | async }}</a>
    </li>
    <li class="nav-item">
        <a  class="nav-link text-dark" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">Odhlásit</a>
    </li>
</ul>
<ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
  <li class="nav-item">
        <a class="nav-link text-dark" [routerLink]='["/authentication/register"]'>Registrovat</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-dark" [routerLink]='["/authentication/login"]'>Přihlásit</a>
    </li>
</ul>
