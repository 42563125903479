<h1>Denní přehled</h1>



<p *ngIf="!vm"><em>Nahrávám...</em></p>

<div *ngIf="vm">
  
  <mat-form-field appearance="fill">
    <mat-label>Vyber den</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)] = "vm.date" (ngModelChange)="dateChange()" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div class="row">
    <div class="col-sm-4">
      <div class="clearfix">
        <h2 class="float-left">Výživová hodnota: {{vm.value | number:'1.0-0'}} kJ</h2>
        <button class="btn btn-default float-right" title="Přidat jídlo..."
          (click)="showNewFoodModal(newListModalTemplate)">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
      <ul class="list-group">
        <li *ngFor="let food of vm.foods; index as i" class="list-group-item"
          [ngClass]="{ 'active': selectedFood == food }" (click)="selectedFood = food">
          <div class="clearfix">
            <div class="float-left">
                {{ food.name }}
            </div>
            <div class="float-right text-right">
                <span class="badge badge-light"> {{ food.value | number:'1.0-0' }} kJ</span>
                <span class="badge badge-light"> {{ food.date  | date:'H:mm'  }} </span> 
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-sm-8" *ngIf="selectedFood">
      <div class="clearfix">
        <h2 class="float-left">{{ selectedFood.name }}</h2>
        <button id="listOptions" class="btn btn-default float-right" title="Upravit jídlo..."
          (click)="showFoodOptionsModal(listOptionsModalTemplate)">
          <fa-icon [icon]="faEllipsisH"></fa-icon>
        </button>
        <button id="saveRecipe" class="btn btn-default float-right" title="Uložit jako recept..."
          (click)="showRecipeModal(recipeModalTemplate)">
          <fa-icon [icon]="faSave"></fa-icon>
        </button>
      </div>
      <ul id="todo-items" class="list-group mb-2">
        <li class="list-group-item" *ngFor="let item of selectedFood.items; index as i">
          <div class="d-flex">
            <div class="flex-fill">
              <div class="todo-item-title"  
              (click)="showItemDetailsModal(itemDetailsModalTemplate, item)" class="form-control item-input-control">
                <span>{{ item.ingredientName }} ({{item.amount}} {{item.unitName}})</span>
              </div>
            </div>
            <div class="todo-item-commands">
              <button *ngIf="item.id != 0" (click)="showItemDetailsModal(itemDetailsModalTemplate, item)"
                class="btn btn-default btn-xs" role="button">
                <fa-icon [icon]="faEllipsisH"></fa-icon>
              </button>
            </div>
          </div>
        </li>
        <li class="list-group-item" (click)="showItemDetailsModal(itemDetailsModalTemplate, null)">
          <button class="btn btn-default">Přidat ingredienci...</button>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="debug">
  <pre>{{ vm | json }}</pre>
</div>

<ng-template #listOptionsModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Jídlo</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="foodOptionsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="inputListTitle">Název</label>
      <input type="text" class="form-control" id="inputListTitle" placeholder="Název jídla..."
        [(ngModel)]="foodOptionsEditor.name" (keyup.enter)="updateFoodOptions()" />
    </div>

    
      <mat-form-field appearance="fill">
        <mat-label>Datum a čas</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)] = "foodOptionsEditor.date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    

    <div *ngIf="debug">
      <pre>{{ foodOptionsEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="clearfix">
      <div class="float-left">
        <button class="btn btn-default text-danger" (click)="confirmDeleteFood(deleteListModalTemplate)">Smazat</button>
      </div>
      <div class="float-right">
        <button class="btn btn-default" (click)="foodOptionsModalRef.hide()">Storno</button>
        <button class="btn btn-primary" (click)="updateFoodOptions()">Uložit</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #recipeModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Recept</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="recipeModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="inputListTitle">Název</label>
      <input type="text" class="form-control" id="recipeTitle" placeholder="Název receptu..."
        [(ngModel)]="recipeEditor.name" (keyup.enter)="saveRecipe()" />
    </div>

    <div *ngIf="debug">
      <pre>{{ recipeEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="clearfix">
      <div class="float-right">
        <button class="btn btn-default" (click)="recipeModalRef.hide()">Storno</button>
        <button class="btn btn-primary" (click)="saveRecipe()">Uložit</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #itemDetailsModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Položka jídla</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="itemDetailsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form">
      <div class="form-group">
        <label for="ingredient">Ingredience</label>
         <ng-select [items]="vm.ingredients" [(ngModel)]="selectedItem.ingredientId" bindLabel="name" bindValue="id" name="ingredient" id="ingredient" groupBy="listName" bi >
          
          <ng-template ng-optgroup-tmp let-item="item" let-index="index">
            <b>{{item.listName}}</b>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="item" let-search="index">
            <div class="ingredientSelector">
               {{item.name}}
               <div class="details">
                 {{item.value}} kJ v {{item.amount}} {{item.unitName}}
               </div>
            </div>
        </ng-template>
       </ng-select>
      </div>

      <div class="form-group">
        <label for="amount">Množství</label>
        <input id="amount" class="form-control" type="number" [(ngModel)]="selectedItem.amount" (ngModelChange)="amountChanged()"/>
      </div>

      <div class="form-group">
        <label for="amount">Kalorická hodnota</label>
        <input id="value" class="form-control" type="number" [(ngModel)]="selectedItem.value" (ngModelChange)="valueChanged()"/>
      </div>

    </div>
    <div *ngIf="debug">
      <pre>{{ selectedItem | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="clearfix">
      <div class="float-left">
        <button class="btn btn-default text-danger" (click)="deleteItem(selectedItem)">Smazat</button>
      </div>
      <div class="float-right">
        <button class="btn btn-default" (click)="itemDetailsModalRef.hide()">Storno</button>
        <button class="btn btn-primary" (click)="saveItem()">Uložit</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteListModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Delete "{{ selectedFood?.name }}"?</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="deleteFoodModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>All items will be permanently deleted. </p>
    <div *ngIf="debug">
      <pre>{{ selectedFood | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button type="button" class="btn btn-default" (click)="deleteFoodModalRef.hide()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="deleteFoodConfirmed()">Delete</button>
    </div>
  </div>
</ng-template>

<ng-template #newListModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Nové jídlo</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="newFoodCancelled()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="name">Název</label>
      <input type="text" class="form-control" id="name" placeholder="Název jídla" [(ngModel)]="newFoodEditor.name"
        [ngClass]="{ 'is-invalid': newFoodEditor.error }" (keyup.enter)="addFood()" />
      <div *ngIf="newFoodEditor.error" class="invalid-feedback">
        {{ newFoodEditor.error }}
      </div>
    </div>
    <div class="form-group">
      <label for="recipe">Recept</label>

     <ng-select [items]="vm.recipes"
           bindLabel="name"
           bindValue="id"
           [(ngModel)]="recipeId">
</ng-select>
    </div>
    <div *ngIf="debug">
      <pre>{{ newFoodEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button class="btn btn-default" (click)="newFoodCancelled()">Cancel</button>
      <button class="btn btn-primary" (click)="addFood()">Create</button>
    </div>
  </div>
</ng-template>
