<h1>Ingredience</h1>

<p>Ingredience použité v jednotlivých jídlech nebo receptech.</p>

<p *ngIf="!vm"><em>Nahrávám...</em></p>

<div *ngIf="vm">

  <div class="row">
    <div class="col-sm-4">
      <div class="clearfix">
        <h2 class="float-left">Seznamy ingrediencí</h2>
        <button class="btn btn-default float-right" title="Přidat seznam..."
          (click)="showNewListModal(newListModalTemplate)">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
      <ul class="list-group">
        <li *ngFor="let list of vm.lists; index as i" class="list-group-item"
          [ngClass]="{ 'active': selectedList == list }" (click)="selectedList = list">
          <div class="clearfix">
            <div class="float-left">
                {{ list.title }}
            </div>
            <div class="float-right text-right">
                <span class="badge badge-light">{{ remainingItems(list) }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-sm-8" *ngIf="selectedList">
      <div class="clearfix">
        <h2 class="float-left">{{ selectedList.title }}</h2>
        <button id="listOptions" class="btn btn-default float-right" title="List Options..."
          (click)="showListOptionsModal(listOptionsModalTemplate)">
          <fa-icon [icon]="faEllipsisH"></fa-icon>
        </button>
      </div>
      <ul id="todo-items" class="list-group mb-2">
        <li class="list-group-item" *ngFor="let item of selectedList.ingredients; index as i">
          <div class="d-flex">
            <div class="flex-fill">
              <div class="todo-item-title"  
              (click)="showItemDetailsModal(itemDetailsModalTemplate, item)" class="form-control item-input-control">
                <span>{{ item.name }}</span>
              </div>
            </div>
            <div class="todo-item-commands">
              <button *ngIf="item.id != 0" (click)="showItemDetailsModal(itemDetailsModalTemplate, item)"
                class="btn btn-default btn-xs" role="button">
                <fa-icon [icon]="faEllipsisH"></fa-icon>
              </button>
            </div>
          </div>
        </li>
        <li class="list-group-item" (click)="showItemDetailsModal(itemDetailsModalTemplate, null)">
          <button class="btn btn-default">Přidat ingredienci...</button>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="debug">
  <pre>{{ vm | json }}</pre>
</div>

<ng-template #listOptionsModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Vlastnosti seznamu</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="listOptionsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="inputListTitle">Název</label>
      <input type="text" class="form-control" id="inputListTitle" placeholder="Název seznamu..."
        [(ngModel)]="listOptionsEditor.title" (keyup.enter)="updateListOptions()" />
    </div>
    <div *ngIf="debug">
      <pre>{{ listOptionsEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="clearfix">
      <div class="float-left">
        <button class="btn btn-default text-danger" (click)="confirmDeleteList(deleteListModalTemplate)">Smazat</button>
      </div>
      <div class="float-right">
        <button class="btn btn-default" (click)="listOptionsModalRef.hide()">Storno</button>
        <button class="btn btn-primary" (click)="updateListOptions()">Aktualizovat</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #itemDetailsModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Detail ingredience</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="itemDetailsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form">
      <div class="form-group">
        <label for="name">Název</label>
        <input id="name" class="form-control" [(ngModel)]="selectedItem.name"/>
      </div>

      <div class="form-group">
        <label for="amount">Množství</label>
        <input id="amount" class="form-control" type="number" [(ngModel)]="selectedItem.amount"/>
      </div>

      <div class="form-group">
        <label for="unit">Jednotka</label>
        <select id="unit" class="form-control" [(ngModel)]="selectedItem.unit">
          <option value="Piece">kus</option>
          <option value="Gram">gram</option>
        </select>
      </div>
      <div class="form-group">
        <label for="value">Hodnota [kJ]</label>
        <input id="value" class="form-control" type="number" [(ngModel)]="selectedItem.value"/>
      </div>
      <div class="form-group">
        <label for="note">Poznámka</label>
        <textarea id="note" class="form-control" rows="3" [(ngModel)]="selectedItem.comment"></textarea>
      </div>
    </div>
    <div *ngIf="debug">
      <pre>{{ selectedItem | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="clearfix">
      <div class="float-left">
        <button class="btn btn-default text-danger" (click)="deleteItem(selectedItem)">Smazat</button>
      </div>
      <div class="float-right">
        <button class="btn btn-default" (click)="itemDetailsModalRef.hide()">Storno</button>
        <button class="btn btn-primary" (click)="saveItem()">Uložit</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteListModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">Delete "{{ selectedList?.title }}"?</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="deleteListModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>All items will be permanently deleted. </p>
    <div *ngIf="debug">
      <pre>{{ selectedList | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button type="button" class="btn btn-default" (click)="deleteListModalRef.hide()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="deleteListConfirmed()">Delete</button>
    </div>
  </div>
</ng-template>

<ng-template #newListModalTemplate>
  <div class="modal-header clearfix">
    <h4 class="modal-title float-left">New List</h4>
    <button type="button" class="close float-right" aria-label="Close" (click)="newListCancelled()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="title">Title</label>
      <input type="text" class="form-control" id="title" placeholder="List title..." [(ngModel)]="newListEditor.title"
        [ngClass]="{ 'is-invalid': newListEditor.error }" (keyup.enter)="addList()" />
      <div *ngIf="newListEditor.error" class="invalid-feedback">
        {{ newListEditor.error }}
      </div>
    </div>
    <div *ngIf="debug">
      <pre>{{ newListEditor | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-right">
      <button class="btn btn-default" (click)="newListCancelled()">Cancel</button>
      <button class="btn btn-primary" (click)="addList()">Create</button>
    </div>
  </div>
</ng-template>
