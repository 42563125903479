import { Component, TemplateRef } from '@angular/core';
import {
    IngredientListDto,
    IngredientDto,
    IngredientListsClient,
    IngredientsClient,
    CreateIngredientListCommand,
    IngredientUnit,
    CreateIngredientCommand,
    IngredientsVm,
    UpdateIngredientCommand
} from '../web-api-client';
import { faPlus, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-ingredients',
    templateUrl: './ingredients.component.html',
    styleUrls: ['./ingredients.component.scss']
})
export class IngredientsComponent {

    debug = false;

    vm: IngredientsVm

    selectedList: IngredientListDto;
    selectedItem: IngredientDto;

    newListEditor: any = {};
    listOptionsEditor: any = {};

    newListModalRef: BsModalRef;
    listOptionsModalRef: BsModalRef;
    deleteListModalRef: BsModalRef;
    itemDetailsModalRef: BsModalRef;

    faPlus = faPlus;
    faEllipsisH = faEllipsisH;

    constructor(private listsClient: IngredientListsClient, private itemsClient: IngredientsClient, private modalService: BsModalService) {
        listsClient.get().subscribe(
            result => {
                this.vm = result;
                if (this.vm.lists.length) {
                    this.selectedList = this.vm.lists[0];
                }
            },
            error => console.error(error)
        );
    }

    // Lists
    remainingItems(list: IngredientListDto): number {
        return list.ingredients.length;
    }

    showNewListModal(template: TemplateRef<any>): void {
        this.newListModalRef = this.modalService.show(template);
        setTimeout(() => document.getElementById("title").focus(), 250);
    }

    newListCancelled(): void {
        this.newListModalRef.hide();
        this.newListEditor = {};
    }

    addList(): void {
        let list = IngredientListDto.fromJS({
            id: 0,
            title: this.newListEditor.title,
            ingredients: [],
        });

        this.listsClient.create(<CreateIngredientListCommand>{ title: this.newListEditor.title }).subscribe(
            result => {
                list.id = result;
                this.vm.lists.push(list);
                this.selectedList = list;
                this.newListModalRef.hide();
                this.newListEditor = {};
            },
            error => {
                let errors = JSON.parse(error.response);

                if (errors && errors.Title) {
                    this.newListEditor.error = errors.Title[0];
                }

                setTimeout(() => document.getElementById("title").focus(), 250);
            }
        );
    }

    showListOptionsModal(template: TemplateRef<any>) {
        this.listOptionsEditor = {
            id: this.selectedList.id,
            title: this.selectedList.title,
        };

        this.listOptionsModalRef = this.modalService.show(template);
    }

    updateListOptions() {
        let  command = new UpdateIngredientCommand ();
        command.id = this.selectedList.id;
        command.name = this.listOptionsEditor.title;

        this.listsClient.update(this.selectedList.id, command)
            .subscribe(
                () => {
                    this.selectedList.title = command.name,
                    this.listOptionsModalRef.hide();
                    this.listOptionsEditor = {};
                },
                error => console.error(error)
            );
    }

    confirmDeleteList(template: TemplateRef<any>) {
        this.listOptionsModalRef.hide();
        this.deleteListModalRef = this.modalService.show(template);
    }

    deleteListConfirmed(): void {
        this.listsClient.delete(this.selectedList.id).subscribe(
            () => {
                this.deleteListModalRef.hide();
                this.vm.lists = this.vm.lists.filter(t => t.id != this.selectedList.id)
                this.selectedList = this.vm.lists.length ? this.vm.lists[0] : null;
            },
            error => console.error(error)
        );
    }

    // Items

    showItemDetailsModal(template: TemplateRef<any>, item: IngredientDto): void {
        if (item == null) {
            this.selectedItem = new IngredientDto();
            this.selectedItem.listId = this.selectedList.id;
            this.selectedItem.unit = IngredientUnit.Gram;
            this.selectedItem.id = 0;
        }
        else {
            this.selectedItem = item;
        }

        this.itemDetailsModalRef = this.modalService.show(template);
    }

    saveItem(): void {

        if (this.selectedItem.id == 0) {
            let command = new CreateIngredientCommand();
            command.name = this.selectedItem.name;
            command.unit = IngredientUnit[this.selectedItem.unit.toString()];
            command.value = this.selectedItem.value;
            command.amount = this.selectedItem.amount;
            command.listId = this. selectedList.id;
            command.comment = this. selectedItem.comment;

            this.itemsClient.create(command)
                .subscribe(
                    result => {
                        this.selectedItem.id = result;
                        this.selectedList.ingredients.push(this.selectedItem);

                        this.itemDetailsModalRef.hide();

                    },
                    error => console.error(error)
                );
        } else {
            let command = new UpdateIngredientCommand();
            command.id = this.selectedItem.id;
            command.name = this.selectedItem.name;
            command.unit = IngredientUnit[this.selectedItem.unit.toString()];
            command.value = this.selectedItem.value;
            command.amount = this.selectedItem.amount;
            command.comment = this. selectedItem.comment;

            this.itemsClient.update(this.selectedItem.id, command)
                .subscribe(
                    () => {
                        console.log('Update succeeded.');

                        this.itemDetailsModalRef.hide();

                    },
                    error => console.error(error)
                );
        }
    }

    // Delete item
    deleteItem(item: IngredientDto) {
        if (this.itemDetailsModalRef) {
            this.itemDetailsModalRef.hide();
        }

        if (item.id == 0) {
            let itemIndex = this.selectedList.ingredients.indexOf(this.selectedItem);
            this.selectedList.ingredients.splice(itemIndex, 1);
        } else {
            this.itemsClient.delete(item.id).subscribe(
                () => this.selectedList.ingredients = this.selectedList.ingredients.filter(t => t.id != item.id),
                error => console.error(error)
            );
        }
    }

}
